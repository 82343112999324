import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Paper";

import { useAuth0 } from "@auth0/auth0-react";
import { LoggedInPageLayout } from "../components/page-layout";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import RequirePermission from "src/authorization/require-permission";
import { AUTHORIZATION_FLIGHTLOG_READ } from "src/authorization/auth-types";

const columns: GridColDef[] = [
  { field: "id", headerName: "Flight ID", width: 90 },
  {
    field: "pilot",
    headerName: "Pilot",
    width: 150,
    editable: false,
  },
  {
    field: "takeoff",
    headerName: "Takeoff time",
    width: 200,
    type: "dateTime",
    editable: false,
  },
  {
    field: "landing",
    headerName: "Landing time",
    type: "dateTime",
    width: 200,
    editable: false,
  },
  {
    field: "duration",
    headerName: "Duration",
    type: "string",
    width: 150,
    editable: false,
  },
  {
    field: "vehicle",
    headerName: "Vehicle",
    description: "ID of vehicle/drone",

    width: 160,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    description: "Pilot remarks",

    width: 160,
  },
];

const rows = [
  {
    id: "NO-2342",
    pilot: "Homer Simpson",
    takeoff: new Date("2023-03-03T13:34:34"),
    landing: new Date("2023-03-03T13:52:34"),
    duration: "18m12s",
    vehicle: "NOR-2134",
  },
  {
    id: "NO-3342",
    pilot: "Homer Simpson",
    takeoff: new Date("2023-03-03T13:34:34"),
    landing: new Date("2023-03-03T13:52:34"),
    duration: "18m12s",
    vehicle: "NOR-2134",
  },
  {
    id: "NO-4342",
    pilot: "Homer Simpson",
    takeoff: new Date("2023-03-03T13:34:34"),
    landing: new Date("2023-03-03T13:52:34"),
    duration: "18m12s",
    vehicle: "NOR-2134",
  },
  {
    id: "NO-5342",
    pilot: "Homer Simpson",
    takeoff: new Date("2023-03-03T13:34:34"),
    landing: new Date("2023-03-03T13:52:34"),
    duration: "18m12s",
    vehicle: "NOR-2134",
  },
  {
    id: "NO-6342",
    pilot: "Homer Simpson",
    takeoff: new Date("2023-03-03T13:34:34"),
    landing: new Date("2023-03-03T13:52:34"),
    duration: "18m12s",
    vehicle: "NOR-2134",
  },
  {
    id: "NO-7342",
    pilot: "Homer Simpson",
    takeoff: new Date("2023-03-03T13:34:34"),
    landing: new Date("2023-03-03T13:52:34"),
    duration: "18m12s",
    vehicle: "NOR-2134",
  },
  {
    id: "NO-8342",
    pilot: "Homer Simpson",
    takeoff: new Date("2023-03-03T13:34:34"),
    landing: new Date("2023-03-03T13:52:34"),
    duration: "18m12s",
    vehicle: "NOR-2134",
  },
];
export const FlightsPage: React.FC = () => {
  return (
    <LoggedInPageLayout>
      <RequirePermission
        to={AUTHORIZATION_FLIGHTLOG_READ}
        fallback={<div>Unauthorised!</div>}
      >
        <Container>
          <Typography variant="h4" component="h1" gutterBottom>
            Flight overview
          </Typography>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </Container>
      </RequirePermission>
    </LoggedInPageLayout>
  );
};
