import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { LoggedInPageLayout } from "../components/page-layout";
import { CardHeader, Container, Divider } from "@mui/material";
import {
  Typography,
  Card,
  CardMedia,
  CardActionArea,
  Button,
  CardContent,
  CardActions,
} from "@mui/material";
import { SpaceBar } from "@mui/icons-material";
import Masonry from "@mui/lab/Masonry";
import { AUTHORIZATION_EQUIPMENT_READ } from "src/authorization/auth-types";
import RequirePermission from "src/authorization/require-permission";

export const DronesPage: React.FC = () => {
  const handleLogin = async () => {};

  return (
    <LoggedInPageLayout>
      <RequirePermission
        to={AUTHORIZATION_EQUIPMENT_READ}
        fallback={<div>Unauthorised!</div>}
      >
        <Container>
          <Typography variant="h4" component="h1" gutterBottom>
            Registered drones
          </Typography>

          <Masonry columns={2} spacing={2}>
            <Card>
              <CardActionArea>
                <CardMedia
                  alt="DroneModel"
                  component="img"
                  title="DroneModel"
                  height="150"
                  image="https://djioslo.no/Userfiles/Upload/images/Modules/Eshop/26620_dji-matrice-300-drone-p6768-11866_image(2).png"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    DJI Matrice 300 RTK
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Serial 1010102023023
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Local id "D12"
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button>Edit</Button>
                <Button>Delete</Button>
              </CardActions>
            </Card>

            <Card>
              <CardActionArea>
                <CardMedia
                  alt="DroneModel"
                  component="img"
                  title="DroneModel"
                  height="150"
                  image="https://djioslo.no/Userfiles/Upload/images/Modules/Eshop/26620_dji-matrice-300-drone-p6768-11866_image(2).png"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    DJI Matrice 300 RTK
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Serial 1010102023023
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Local id "D12"
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button>Edit</Button>
                <Button>Delete</Button>
              </CardActions>
            </Card>
          </Masonry>

          <Button variant="contained" size="large" onClick={handleLogin}>
            Register new drone
          </Button>
        </Container>
      </RequirePermission>
    </LoggedInPageLayout>
  );
};
