import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState, useCallback } from "react";
import {
  GetUserInfoCommand,
  GetUserInfoResponse,
} from "@airdodge-private/api-typescript-public/lib/io/airdodge/user/v1/UserService_API_Commands_pb";



import { UserService } from "@airdodge-private/api-typescript-public/lib/io/airdodge/user/v1/UserService_API_connect";
import { useClient } from "../api-client";
import { ConnectError, Code } from "@connectrpc/connect";

import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Stack,
} from "@mui/material";
import { LoggedInPageLayout } from "src/components/page-layout";
import RequirePermission from "src/authorization/require-permission";
import { AUTHORIZATION_USER_READ } from "src/authorization/auth-types";

export const ProfilePage: React.FC = () => {
  const { user } = useAuth0();
  const [userData, setUserData] = useState<GetUserInfoResponse>();

  const { getAccessTokenSilently } = useAuth0();
  const client = useClient(UserService);

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();

      const request = new GetUserInfoCommand({});
      const headers = new Headers();
      headers.set("Authorization", "Bearer " + accessToken);

      try {
        const resp: GetUserInfoResponse = await client.getUserInfo(request, {
          headers: headers,
          timeoutMs: 10000,
        });

        console.log(resp);

        setUserData(resp);
      } catch (err) {
        if (err instanceof ConnectError && err.code === Code.DeadlineExceeded) {
          console.log("Timeout");
        }
      }

      if (!isMounted) {
        return;
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);
  if (!user) {
    return null;
  }

  return (
    <LoggedInPageLayout>
           <RequirePermission
        to={AUTHORIZATION_USER_READ}
        fallback={<div>Unauthorised!</div>}
      >
 
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Profile page
          </Typography>
          <Card>
            <CardContent>
              <Stack>
                <Typography variant="h6" component="h1" gutterBottom>
                  Logged in user: {user.name}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="h6" component="h1" gutterBottom>
                  Email: {user.email}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="h6" component="h1" gutterBottom>
                  {userData?.firstName} {userData?.lastName}
                </Typography>
              </Stack>
              <CardMedia src={user.picture} />
            </CardContent>
          </Card>
        </Box>
      </Container>
      </RequirePermission>
    </LoggedInPageLayout>
  );
};
