// Spinner.tsx
import React, { useEffect } from 'react';
import './spinner.css'; // Make sure to create this CSS file

interface SpinnerProps {
    loading: Boolean;
}
  

const Spinner: React.FC<SpinnerProps> = ({ loading }) => {

    useEffect(() => {
        console.log('Spinner Component re-rendered');
    });

    if (loading) {
        return (
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          );
    } else {
        return (
            <div>
            </div>
          );
    }

};

export default Spinner;
