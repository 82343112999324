import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState, useCallback } from "react";
import { LoggedInPageLayout } from "../components/page-layout";
import GeoJSON from "geojson";

import {
  Map,
  Layer,
  Source,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  ViewStateChangeEvent,
  MapboxEvent,
  MapLayerMouseEvent,
} from "react-map-gl";
import { GeoZonesService } from "@airdodge-private/api-typescript-public/lib/io/airdodge/dtsp/geozones/v1/GeoZones_API_connect";
import { useClient } from "../api-client";
import { fillExtrusionLayer, fillExtrusionOutlineLayer } from "./map-style";
import { Config } from "src/config";
import { Typography, Container, Box } from "@mui/material";
import type { GeoJSONSource, SkyLayer } from "react-map-gl";
import mapboxgl, { LngLat, MapLayerEventType } from "mapbox-gl";
import * as turf from "@turf/turf";
import RequirePermission from "src/authorization/require-permission";
import { AUTHORIZATION_GEOZONES_READ } from "src/authorization/auth-types";
import Spinner from "src/components/spinner";
import { json } from "react-router-dom";

enum AltitudeUnit {
  METER,
  FEET,
  FLIGHT_LEVEL
}

enum AltitudeType {
  MSL,
  AGL,
  STANDARD_PRESSURE,
  UNLIMITED
}
interface AltitudeDefinition {
  value : number;
  unit : AltitudeUnit;
  type : AltitudeType;
}

let lastController: AbortController | null = null;


export const MapPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState<boolean>(false);

  const [allData, setAllData] =
    useState<
      GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    >();
  const skyLayer: SkyLayer = {
    id: "sky",
    type: "sky",
    paint: {
      "sky-type": "atmosphere",
      "sky-atmosphere-sun": [0.0, 0.0],
      "sky-atmosphere-sun-intensity": 15,
    },
  };

  async function fetchData(bounds: mapboxgl.LngLatBounds, zoomLevel: number) {
    if (zoomLevel >= 5.5) {
      const accessToken = await getAccessTokenSilently();

      if (lastController) {
        lastController.abort();
      }

      const controller = new AbortController();
      const signal = controller.signal;
      lastController = controller;

      let northEastLngCorrection;
      let northEastLatCorrection;
      let southWestLngCorrection;
      let southWestLatCorrection;

      if (zoomLevel >= 5.5 && zoomLevel <= 9) {
        northEastLngCorrection = 0.2;
        northEastLatCorrection = 0.2;
        southWestLngCorrection = 1;
        southWestLatCorrection = 1;
      } else {
        northEastLngCorrection = 0.002;
        northEastLatCorrection = 0.002;
        southWestLngCorrection = 0;
        southWestLatCorrection = 0.002;
      }


      setLoading(() => true);

      fetch(
        Config.api_core_url +
          "/geojson?zoomLevel=" +
          zoomLevel +
          "&bbox=" +
          (bounds.getNorthEast().lng + northEastLngCorrection) +
          "," +
          (bounds.getNorthEast().lat + northEastLatCorrection) +
          "," +
          (bounds.getSouthWest().lng - southWestLngCorrection) +
          "," +
          (bounds.getSouthWest().lat - southWestLatCorrection),
        {
          signal,
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
        .then((resp) => resp.json())
        .then((json) => setAllData(json))
        .catch(
          (err) => {
            
            if (err.name === 'AbortError') {
              console.log('Fetch aborted');
            } else {
              console.error("Could not load data", err)
            }

          }
        )
        .finally(() => setLoading(() => false)); // eslint-disable-line
    } else {
      setAllData({
        type: 'FeatureCollection',
        features: []
      });
    }
  }

  function createRoutes(paths: number[][][]) {
    const routes: GeoJSON.FeatureCollection<
      GeoJSON.Geometry,
      GeoJSON.GeoJsonProperties
    > = {
      type: "FeatureCollection",
      features: [],
    };

    for (let i = 0; i < paths.length; i++) {
      routes.features.push({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: paths[i],
        },
      });
    }

    return routes;
  }

  function createVehicles(paths: number[][]) {
    const vehicles: GeoJSON.FeatureCollection<
      GeoJSON.Geometry,
      GeoJSON.GeoJsonProperties
    > = {
      type: "FeatureCollection",
      features: [],
    };

    for (let i = 0; i < paths.length; i++) {
      vehicles.features.push({
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: paths[i],
        },
      });
    }

    return vehicles;
  }

  function onLoad(event: MapboxEvent) {
    console.log("Map loaded");
    const map = event.target;
    map.resize();

    // Blakstad

    const blakstad = [10.494940024521444, 59.85114662120456];

    const bygdøy = [10.68774082623315, 59.8971080269415];

    // Bjørvika
    const bjørvika = [10.751288, 59.901483];

    const kjelsås = [10.783793572895659, 59.96704466034484];

    const gardermoen = [11.089377588483567, 60.18086449487984];

    const tryvann = [10.666249678539545, 59.98667273832196];

    const nesoddtangen = [10.656995052573631, 59.870255684923904];

    const routes = createRoutes([
      [blakstad, bjørvika, blakstad],
      [bjørvika, blakstad, bjørvika],
      [bjørvika, kjelsås, gardermoen],
      [kjelsås, gardermoen],
      [tryvann, bygdøy],
      [nesoddtangen, bygdøy, kjelsås],
    ]);

    const points = createVehicles([
      bjørvika,
      blakstad,
      bjørvika,
      kjelsås,
      tryvann,
      nesoddtangen,
    ]);

    // Number of steps to use in the arc and animation, more steps means
    // a smoother arc and animation, but too many steps will result in a
    // low frame rate
    const steps = 5000;

    // Draw an arc between the `origin` & `destination` of the two points

    for (let i = 0; i < routes.features.length; i++) {
      const lineDistance = turf.length(routes.features[i]);
      const arc = [];
      for (let x = 0; x < lineDistance; x += lineDistance / steps) {
        const f: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> =
          routes.features[i];
        const line = f.geometry as GeoJSON.LineString;
        const segment = turf.along(line, x);
        arc.push(segment.geometry.coordinates);
      }

      // Update the route with calculated arc coordinates
      const firstFeature = routes.features[i] as GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
      >;

      const line: GeoJSON.LineString =
        firstFeature.geometry as GeoJSON.LineString;
      line.coordinates = arc;
    }

    // Calculate the distance in kilometers between route start/end point.

    map.addSource("route", {
      type: "geojson",
      data: routes,
    });

    map.addSource("point", {
      type: "geojson",
      data: points,
    });

    /* map.addLayer({
      id: "route",
      source: "route",
      type: "line",
      paint: {
        "line-width": 2,
        "line-color": "#007cbf",
      },
    });*/

    map.addLayer({
      id: "point",
      source: "point",
      type: "symbol",
      layout: {
        // This icon is a part of the Mapbox Streets style.
        // To view all images available in a Mapbox style, open
        // the style in Mapbox Studio and click the "Images" tab.
        // To add a new image to the style at runtime see
        // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
        "icon-image": "drone",
        "icon-size": 1.5,
        "icon-rotate": ["get", "bearing"],
        "icon-rotation-alignment": "map",
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
      },
    });
    let running = false;
    // Used to increment the value of the point measurement against the route.
    let counter = 0;
    function animate() {
      running = true;

      for (let i = 0; i < points.features.length; i++) {
        if (counter >= steps - 1) {
          counter = 0;
        }

        //  document.getElementById("replay").disabled = true;
        const currentPosition = (
          routes.features[i].geometry as GeoJSON.LineString
        ).coordinates[counter >= steps ? counter - 1 : counter];
        const nextPosition = (routes.features[i].geometry as GeoJSON.LineString)
          .coordinates[counter >= steps ? counter : counter + 1];
        if (!currentPosition || !nextPosition) {
          running = false;
          // document.getElementById("replay").disabled = false;
          return;
        }
        // Update point geometry to a new position based on counter denoting
        // the index to access the arc
        (points.features[i].geometry as GeoJSON.Point).coordinates = (
          routes.features[i].geometry as GeoJSON.LineString
        ).coordinates[counter];

        // Calculate the bearing to ensure the icon is rotated to match the route arc
        // The bearing is calculated between the current point and the next point, except
        // at the end of the arc, which uses the previous point and the current point

        points.features[i].properties!.bearing = turf.bearing(
          turf.point(currentPosition),
          turf.point(nextPosition)
        );
      }
      // Update the source with this new data
      (map.getSource("point") as GeoJSONSource).setData(points);

      // Request the next frame of animation as long as the end has not been reached
      if (counter < steps) {
        requestAnimationFrame(animate);
      }

      counter = counter + 1;
    }

    animate();
  }

  function onClick(event: MapLayerMouseEvent) {
    const features = event.target.queryRenderedFeatures(event.point, {
      layers: ["zoneLayer"], // replace with your layer name
    });
    if (!features.length) {
      return;
    }
    const feature: GeoJSON.Feature = features[0];

    var coordinates: number[] = [0, 0];

    if (feature.geometry.type === "Point") {
      const point: GeoJSON.Point = feature.geometry;
      coordinates = point.coordinates.slice();
    } else if (feature.geometry.type === "Polygon") {
      const polygon: GeoJSON.Polygon = feature.geometry;
      coordinates = polygon.coordinates[0][0];
    }


    var idText = feature.id!;
    var nameText =  feature.properties!.name ? feature.properties!.name : "(No name)";
    var featureTypeText = feature.properties!.type;
    const upper : AltitudeDefinition = JSON.parse(feature.properties!.upperLimit) as AltitudeDefinition
    var upperText = upper.value + " "+upper.unit + " "+upper.type;
    
    const lower : AltitudeDefinition = JSON.parse(feature.properties!.lowerLimit) as AltitudeDefinition
    var lowerText = lower.value + " "+lower.unit + " "+lower.type;

    var popupHTML = "<b>"+nameText+"</b>"+"<br/>"+featureTypeText+"<br/>"+"Upper: "+upperText+"</br>"+"Lower: "+lowerText+"</br>"+"ID: "+idText;

    if (featureTypeText.startsWith("airspace:notam:")) {
      popupHTML = popupHTML + "<pre style=\"white-space: pre-wrap; width: 395px;\">" + feature.properties!.description + "</pre>"
    }


    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    new mapboxgl.Popup()
      .setMaxWidth("410px")
      .setLngLat(new LngLat(coordinates[0], coordinates[1]))
      .setHTML(popupHTML)
      .addTo(event.target);

    // Copy coordinates array.
  }

  const onViewStateChanged = useCallback((event: ViewStateChangeEvent) => {
    const zoom = event.target.getZoom();
    const bounds = event.target.getBounds();
    fetchData(bounds, zoom);
  }, []);

  return (
    <LoggedInPageLayout>
      <RequirePermission
        to={AUTHORIZATION_GEOZONES_READ}
        fallback={<div>Unauthorised!</div>}
      >
        <Container>
          <Typography variant="h4" component="h1" gutterBottom>
            3D Planning map
          </Typography>
          <Box display="flex" justifyContent="space-between" p={0}>
            <Typography variant="body1" component="h1" gutterBottom>
              Use mouse to tilt and rotate map
            </Typography>
            <Spinner loading={loading}/>
          </Box>
          <Box> 
            <Map
              id="map"
              mapLib={import("mapbox-gl")}
              mapboxAccessToken="pk.eyJ1IjoiYXJuZWRvZGdlIiwiYSI6ImNsbmRhcnI1djAzYTEyanFwaXJpMzd1Y24ifQ.iBmdW1js0Oaq0s3wvdwDyA"
              initialViewState={{
                longitude: 10.70553025100827,
                latitude: 59.91335277181008,
                zoom: 10,
                pitch: 60,
              }}
              style={{ width: "80vw", height: "80vh" }}
              mapStyle="mapbox://styles/arnedodge/clo17r18x00co01pk2d3m4gcg"
              onMoveEnd={onViewStateChanged}
              terrain={{ source: "mapbox-dem", exaggeration: 1.0 }}
              // onLoad={onLoad}
              onClick={onClick}
            >
              <FullscreenControl position="top-left" />
              <NavigationControl position="top-left" />
              <ScaleControl />

              <Source type="geojson" data={allData} promoteId={'id'}>
                <Layer {...fillExtrusionLayer} id="zoneLayer" />
              </Source>
              <Source
                id="mapbox-dem"
                type="raster-dem"
                url="mapbox://mapbox.mapbox-terrain-dem-v1"
                tileSize={512}
                maxzoom={14}
              />
              <Layer {...skyLayer} />
            </Map>
          </Box>
        </Container>
      </RequirePermission>
    </LoggedInPageLayout>
  );
};
