import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LoginButton } from "../components/buttons/login-button";
import { Grid, Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { SignupButton } from "src/components/buttons/signup-button";
import logo from "../images/FullLogo.png";
import vendor1 from "../images/vendors/logo_placeholder.png";
import vendor2 from "../images/vendors/logo_placeholder.png";
import vendor3 from "../images/vendors/logo_placeholder.png";
import map from "../images/vendors/map.png";

export const HomePage: React.FC = () => (
  <Container maxWidth="xl">
    <Grid container>
      <Grid item xs={9}>
        <img src={logo} alt="logo" width="50%" />
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" size="large" href="https://storage.googleapis.com/apidocs.dev.airdodge.io/index.html">Developer portal
    </Button>&nbsp;
          <LoginButton />
        </Box>
      </Grid>

      <Grid item xs={8}>
        <Grid container rowGap={3}>
          <Grid xs={12}>
            <Typography variant="h4" component="h1" gutterBottom>
              AirDodge provides Drone Traffic Services
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h5" component="h1">
              Why choose AirDodge?
            </Typography>
          </Grid>

          <Grid xs={1}>
            <ArrowForward />
          </Grid>
          <Grid xs={11}>
            <Typography variant="h6" component="h1">
              Most comprehensive and updated data on where you can fly and
              cannot - made easy to understand
            </Typography>
          </Grid>
          <Grid xs={1}>
            <ArrowForward />
          </Grid>
          <Grid xs={11}>
            <Typography variant="h6" component="h1">
              Live air traffic information covering all aerial vehicles with
              position reporting
            </Typography>
          </Grid>
          <Grid xs={1}>
            <ArrowForward />
          </Grid>
          <Grid xs={11}>
            <Typography variant="h6" component="h1">
              Integrated with most popular mission planning and execution tools
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box flexDirection="column"></Box>
          </Grid>

          <Grid item xs={12}>
            <Box flexDirection="column" justifyContent="center">
              <img src={vendor1} alt="Vendor 1" height="120" />
              <img src={vendor2} alt="Vendor 2" height="120" />
              <img src={vendor3} alt="Vendor 3" height="120" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box flexDirection="column"></Box>
          </Grid>
          <Grid item xs={12}>
            <Box flexDirection="column"></Box>
          </Grid>
        </Grid>

    
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Our coverage
        </Typography>
        (demo image)
        <img src={map} alt="Coverage map" width="100%" />
      </Grid>
    </Grid>
  </Container>
);
