import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { LoggedInPageLayout } from "../components/page-layout";
import { Grid, Container, Typography } from "@mui/material";
import RequirePermission from "src/authorization/require-permission";
import { AUTHORIZATION_ORGANISATION_READ } from "src/authorization/auth-types";

export const OrganisationPage: React.FC = () => {
  return (
    <LoggedInPageLayout>
      <RequirePermission
        to={AUTHORIZATION_ORGANISATION_READ}
        fallback={<div>Unauthorised!</div>}
      >
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4" component="h1" gutterBottom>
                Operator overview
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" component="h1" gutterBottom>
                Name
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6" component="h1" gutterBottom>
                UAV Unlimited Inc
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" component="h1" gutterBottom>
                Operator number
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6" component="h1" gutterBottom>
                # NOR92dsxvsesfsddf-abc
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" component="h1" gutterBottom>
                Country
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6" component="h1" gutterBottom>
                NO
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" component="h1" gutterBottom>
                Contact person
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6" component="h1" gutterBottom>
                Ola Nordmann
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" component="h1" gutterBottom>
                Contact number
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6" component="h1" gutterBottom>
                +47 99574896
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </RequirePermission>
    </LoggedInPageLayout>
  );
};
