import React from "react";
import { LoggedInPageLayout } from "../components/page-layout";
import { Typography } from "@mui/material";

export const NotFoundPage: React.FC = () => {
  return (
    <LoggedInPageLayout>
      <Typography variant="h4" component="h1" gutterBottom>
        Not found!
      </Typography>
    </LoggedInPageLayout>
  );
};
