import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Box,
  CssBaseline,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { useNavigate } from "react-router-dom";

import InboxIcon from "@mui/icons-material/MoveToInbox";

import logo from "../images/SmallLogo.png";
import { LogoutButton } from "./buttons/logout-button";

import PermissionProvider from "src/authorization/auth-provider";
import { AUTHORIZATION_GEOZONES_READ, AUTHORIZATION_USER_READ,AUTHORIZATION_EQUIPMENT_READ,AUTHORIZATION_FLIGHTLOG_READ,AUTHORIZATION_ORGANISATION_READ, Permission } from "../authorization/auth-types";
import { useAuth0 } from "@auth0/auth0-react";
import RequirePermission from "src/authorization/require-permission";
import { jwtDecode } from "jwt-decode";


interface Props {
  children: JSX.Element;
}

type DecodedToken = {
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  permissions: string[];
  scope: string;
  sub: string;
};


export const LoggedInPageLayout: React.FC<Props> = ({ children }) => {
  let navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const { getAccessTokenSilently } = useAuth0();
  const fetchPermission =
    () =>
    async (permission: Permission): Promise<boolean> => {
      return new Promise<boolean>((resolve, reject) => {
        getAccessTokenSilently()
          .then((claims) => {
            const decoded: DecodedToken = jwtDecode(claims);
            const result = decoded.permissions.includes(permission);
         resolve(result);
          })
          .catch((err) => {
            console.debug("Error obtaining access token", err);
            reject();
          });
      });
    };


  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    path: string
  ) => {
    setSelectedIndex(index);
    navigate(path);
  };

  return (
    <div>
          <PermissionProvider fetchPermission={fetchPermission()}>
  
      <CssBaseline />

      <Grid container>
        <Grid xs={10}></Grid>

        <Grid>
          <LogoutButton />
        </Grid>
        <Grid xs={12}>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Drawer
                sx={{
                  width: 250,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: 250,
                    boxSizing: "border-box",
                  },
                }}
                variant="permanent"
                anchor="left"
              >
                <img src={logo} width="80" alt="logo" />
                <List>


                <RequirePermission to={AUTHORIZATION_USER_READ} >
          
                  <ListItem key="profile" disablePadding>
                    <ListItemButton
                      selected={selectedIndex === 0}
                      onClick={(event) =>
                        handleListItemClick(event, 0, "/profile")
                      }
                    >
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="My page" />
                    </ListItemButton>
                  </ListItem>
                  </RequirePermission>
                  <RequirePermission to={AUTHORIZATION_ORGANISATION_READ}>
              
                  <ListItem key="organisation" disablePadding>
                    <ListItemButton
                      selected={selectedIndex === 1}
                      onClick={(event) =>
                        handleListItemClick(event, 1, "/organisation")
                      }
                    >
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="My organisation" />
                    </ListItemButton>
                  </ListItem>
                  </RequirePermission>
            
                  <RequirePermission to={AUTHORIZATION_EQUIPMENT_READ}>
          
                  <ListItem key="drones" disablePadding>
                    <ListItemButton
                      selected={selectedIndex === 2}
                      onClick={(event) =>
                        handleListItemClick(event, 2, "/drones")
                      }
                    >
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="My drones" />
                    </ListItemButton>
                  </ListItem>
                  </RequirePermission>  

                  <RequirePermission to={AUTHORIZATION_GEOZONES_READ}>
          
                  <ListItem key="maps" disablePadding>
                    <ListItemButton
                      selected={selectedIndex === 3}
                      onClick={(event) =>
                        handleListItemClick(event, 3, "/maps")
                      }
                    >
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="Planning map" />
                    </ListItemButton>
                  </ListItem>
                  </RequirePermission>
                  <RequirePermission to={AUTHORIZATION_FLIGHTLOG_READ}>
          
                  <ListItem key="flights" disablePadding>
                    <ListItemButton
                      selected={selectedIndex === 4}
                      onClick={(event) =>
                        handleListItemClick(event, 4, "/flights")
                      }
                    >
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="Flights" />
                    </ListItemButton>
                  </ListItem>
                  </RequirePermission>
                </List>
              </Drawer>
            </Box>
            <Box>{children}</Box>
          </Box>
        </Grid>
      </Grid>
      </PermissionProvider>
    </div>
  );
};
