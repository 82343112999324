import type { LayerProps } from "react-map-gl";

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const fillExtrusionLayer: LayerProps = {
  id: "data",
  type: "fill-extrusion",

  paint: {
    // Get the `fill-extrusion-color` from the source `color` property.
    "fill-extrusion-color": [
      "get",
      "fillColor",
      ["get", "display", ["properties"]],
    ],
    // Get `fill-extrusion-height` from the source `height` property.
    "fill-extrusion-height": ["coalesce", ["get", "upperLimitMeters"], 10000],

    // Get `fill-extrusion-base` from the source `base_height` property.
    "fill-extrusion-base": ["get", "lowerLimitMeters"],

    // Make extrusions slightly opaque to see through indoor walls.
    "fill-extrusion-opacity": 0.4,
  },
};

export const fillExtrusionOutlineLayer: LayerProps = {
  id: "outline",
  type: "line",

  paint: {
    "line-color": "#000",
    "line-width": 2,
  },
};
