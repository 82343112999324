export type Permission = string;

export const AUTHORIZATION_GEOZONES_READ : string = "geozones:read";

export const AUTHORIZATION_USER_READ : string = "user:read";

export const AUTHORIZATION_ORGANISATION_READ : string = "organisation:read";

export const AUTHORIZATION_FLIGHTLOG_READ : string = "flights:read";

export const AUTHORIZATION_EQUIPMENT_READ : string = "equipment:read";
